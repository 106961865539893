@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url("./assets/GothamBook.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'GothamBook', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.media-postcontent {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-container {
  width: 270px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
}

.ql-mention-list-item {
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 4px;
}

/* .MuiTableCell-alignLeft{
  /* padding-right: 7px; */

  .input-checkbox {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .checkbox {
    display: inline-block;
    height: 18px;
    width: 18px;
    background: #fff;
    border: 3px rgb(118, 118, 118) solid;
    border-radius: 3px;
    margin-right: 4px;
    cursor: pointer;
  }
  
  .checkbox--active {
    border-color: #43455A;
    background: #43455A;
  }
  
  .custom-arrow {
    /* position: absolute;
    top: -40px; */
    font-size: 24px;
    /* background-color: transparent;
    border: none;
    outline: none; */
    cursor: pointer;
    z-index: 1;
  }
  
  .custom-arrow-left {
    left: 0;
  }
  
  .custom-arrow-right {
    right: 0;
  }